<template>
  <div>
    <dialog-form-list
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
      @openDialog="openDialog"
    >
    </dialog-form-list>
    <!-- 弹出表格 -->
    <dialog-table-list
      width="1200px"
      v-if="showDialogTableList"
      ref="tableList"
      :title="dialogTitle"
      :url="dialogUrl"
      :defaultParams="dialogDefaultParams"
      :columns="dialogColumns"
      :formData="dialogFormData"
      :config="dialogConfig"
      v-model="dialogTableRow"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
      @search="dialogSearch"
      :multiple="true"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 所属部门 -->
      <template v-slot:accountGroup="{ row }">
        {{ $util.getTreeNames(row.accountGroup, 'name') }}
      </template>
      <!-- 状态 -->
      <template v-slot:enable="{ row }">
        {{ enableObj[row.enable] }}
      </template>
    </dialog-table-list>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      cmd: this.$store.state.cmd,
      formData: {
        contentText: this.$t('keyDistribution.remoteGuidance')
      },
      config: [
        // lang:分配账号
        {
          tag: 'dialog-define',
          label: this.$t('keyDistribution.assignAccount'),
          prop: 'accountIdSet',
          hidden: false,
          rules: {
            noNull: true,
          },
          tagEvents: {
            // 显示名称
            showName(data) {
             const idsStr = data.map(obj =>{
                  return obj.name
              }).join(",")
              return idsStr
            },
          },
        },
        // lang:密钥类型
        {
          tag: 'el-select',
          label: this.$t('keyDistribution.keyType'),
          prop: 'typeOf',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.secretKeyType(),
          },
          tagEvents:{
            change(type) {
              if(type === 'PRODUCT_KEY') {
                self.config[2].hidden = false
                self.config[3].hidden = true
                if(self.$util.getLocalStorage('apiprefix') === 'tmva') {
                  self.formData.contentText = self.$t('businessEnum.TMVA')
                } else if(self.$util.getLocalStorage('apiprefix') === 'workFlow') {
                  self.formData.contentText = self.$t('businessEnum.XR_WORK')
                } else if(self.$util.getLocalStorage('apiprefix') === 'tmvaAiStation') {
                  self.formData.contentText = self.$t('businessEnum.AI_STATION')
                }
              }else{
                self.config[2].hidden = true
                self.config[3].hidden = false
              }
            }
          }
        },
        // lang:分配内容
        {
          label: this.$t('keyDistribution.content'),
          prop: 'contentText',
          hidden: true,
          rules: {
            noNull: true,
          },
          tagProps:{
            readonly:'readonly'
          }
        },
        // lang:分配内容
        {
          tag: 'el-select',
          label: this.$t('keyDistribution.content'),
          prop: 'contentType',
          hidden: true,
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.functionKeyType(),
          },
        },
        // lang:密钥有效期（月）
        {
          label: this.$t('keyDistribution.validMonths'),
          prop: 'validNum',
          rules: {
            noNull: true,
             type: 'positiveInt',
          },
        },
        {
          tag: 'el-select',
          label: this.$t('keyDistribution.workflowProductVersion'),
          prop: 'versionTypeList',
          hidden: self.$util.getLocalStorage('apiprefix') !== 'workFlow',
          rules: {
            noNull: true,
          },
          tagProps: {
            multiple: true,
            options: [],
          },
        }
      ],
      enableObj: this.$util.listToObj(this.$dict.enable()),
      // 弹出表格
      showDialogTableList: false,
      dialogTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {},
      dialogColumns: [],
      dialogFormData: {},
      dialogConfig: {},
      dialogTableRow: [],
      hasVersionType:[]
    }
  },
  computed: {
    ...mapGetters([
        'company'
    ]),
  },
  mounted() {
    if (this.$util.getLocalStorage('apiprefix') === 'workFlow') {
      this.$ajax({
        url: this.$api.findCompanyProductVersion,
        data: {
          companyUid: this.company.uid
        }
      }).then(res => {
        res.forEach(item => {
          if(item.isGrant === 'YES') {
            this.hasVersionType.push(item.versionType)
          }
        })
        this.config[5].tagProps.options = this.$dict.workflowProductVersion().filter(item => this.hasVersionType.includes(item.value))
      })
    }
    this.setDefaultValue()
  },
  methods: {
 // 确认表格弹框
    dialogConfirm(row) {
      this.formData[this.targetProp] = row
      this.showDialogTableList = false
    },
    // 打开表格弹框
    openDialog(item) {
      this.targetProp = item.prop
      // this.dialogTableRow = this.formData[item.prop] || null
      // 选择设备
      this.dialogTitle = this.$t('adminAccount.companyInfo')
      this.dialogUrl = '/core'  + this.$api.userAccountFind
      this.dialogDefaultParams = {
        companyId: this.$store.getters.company.id,
      }
      this.dialogColumns = [
         // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // lang:所属部门
        {
          label: this.$t('adminAccount.accountGroup'),
          prop: 'accountGroup',
        },
        // lang:状态
        {
          label: this.$t('base.enable'),
          prop: 'enable',
        },
        // lang:姓名
        {
          label: this.$t('adminAccount.name'),
          prop: 'name',
        },
        // lang:手机号
        {
          label: this.$t('adminAccount.mobile'),
          prop: 'mobile',
        },
        // lang:登录名
        {
          label: this.$t('adminAccount.loginName'),
          prop: 'accountId',
        },
        // lang:昵称
        {
          label: this.$t('adminAccount.nickname'),
          prop: 'nickName',
        },
      ]
      this.dialogConfig = [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 9,
          tagProps: {
            placeholder: this.$t('companyManage.nameOrNotes'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 11,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ]
      this.showDialogTableList = true
    },
    // 关闭表格弹框
    dialogCancel() {
      this.showDialogTableList = false
    },
    // 表格弹框搜索
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.tableList.searchList(dialogFormData)
    },
    // 设置默认值
    setDefaultValue() {
      console.log(this.formData)
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {

      const { contentType, typeOf, accountIdSet} = formData
      if(typeOf === 'PRODUCT_KEY') {
        formData.type = 'PRODUCT_KEY'
      } else {
        formData.type = contentType
      }
      const accountUid  = accountIdSet.map(obj =>{ return obj.uid})
      formData.accountUidSet = accountUid
      delete formData.contentType
      delete formData.typeOf
      delete formData.contentText
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + (this.$util.getLocalStorage('apiprefix') === 'workFlow'?this.$api.batchAuthorize:this.$api.addNewEntity),
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$forceUpdate()
        this.$emit('closePage', 'add')
      })
    },
  },
}
</script>
